<template>
  <div data-sel="page-admn">
    <CSpinner v-if="loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
  <CRow>
    <CCol sm="4">
      <CCallout color="success">
        <small class="text-muted">Created</small><br>
        <strong class="h4">{{this.created}}</strong>
      </CCallout>
    </CCol>
    <CCol sm="4">
      <CCallout color="info">
        <small class="text-muted">Updated</small><br>
        <strong class="h4">{{this.updated}}</strong>
      </CCallout>
    </CCol>
    <CCol sm="4">
      <CCallout color="danger">
        <small class="text-muted">Deleted</small><br>
        <strong class="h4">{{this.deleted}}</strong>
      </CCallout>
    </CCol>
  </CRow>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createAccountOwnerViewData"
    >
      Create account owner view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createBillingArticlesViewData"
    >
      Create billing article view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createClientViewData"
    >
      Create client view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createContractViewData"
    >
      Create contract view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createCountryViewData"
    >
      Create country view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createNUTS1RegionViewData"
    >
      Create NUTS1 region view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createNUTS2RegionViewData"
    >
      Create NUTS2 region view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createNUTS3RegionViewData"
    >
      Create NUTS3 region view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createPostalCodeViewData"
    >
      Create postal code view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createProductViewData"
    >
      Create product view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createProductGroupViewData"
    >
      Create product group view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createReportRecipientViewData"
    >
      Create report recipient view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createRevenueAccountViewData"
    >
      Create revenue accounts view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createSalesAgentViewData"
    >
      Create sales agent view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createSalesOfficeViewData"
    >
      Create sales office view data
    </CButton>
    <CButton
        data-sel="btn-trigger-index"
        class="btn-view-trigger"
        variant="outline"
        color="info"
        @click="createVendorViewData"
    >
      Create vendor view data
    </CButton>
  </div>
</template>

<script>
import gql from "graphql-tag";
const createViewAccountOwners = gql`
  mutation {
    createViewAccountOwners{
      create
      update
      delete
    }
  }
`
const createViewBillingArticles = gql`
  mutation {
    createViewBillingArticles{
      create
      update
      delete
    }
  }
`
const createViewVendors = gql`
  mutation {
    createViewVendors{
      create
      update
      delete
    }
  }
`
const createViewSalesOffices = gql`
  mutation {
    createViewSalesOffices{
      create
      update
      delete
    }
  }
`
const createViewSalesAgents = gql`
  mutation {
    createViewSalesAgents{
      create
      update
      delete
    }
  }
`
const createViewCountries = gql`
  mutation {
    createViewCountries{
      create
      update
      delete
    }
  }
`
const createViewNUTS1Regions = gql`
  mutation {
    createViewNUTS1Regions{
      create
      update
      delete
    }
  }
`
const createViewNUTS2Regions = gql`
  mutation {
    createViewNUTS2Regions{
      create
      update
      delete
    }
  }
`
const createViewNUTS3Regions = gql`
  mutation {
    createViewNUTS3Regions{
      create
      update
      delete
    }
  }
`
const createViewPostalCodes = gql`
  mutation {
    createViewPostalCodes{
      create
      update
      delete
    }
  }
`
const createViewProducts = gql`
  mutation {
    createViewProducts{
      create
      update
      delete
    }
  }
`
const createViewProductGroups = gql`
  mutation {
    createViewProductGroups{
      create
      update
      delete
    }
  }
`
const createViewClients = gql`
  mutation {
    createViewClients{
      create
      update
      delete
    }
  }
`
const createViewContracts = gql`
  mutation {
    createViewContracts{
      create
      update
      delete
    }
  }
`
const createViewRevenueAccounts = gql`
  mutation {
    createViewRevenueAccounts{
      create
      update
      delete
    }
  }
`
const createViewReportRecipients = gql`
  mutation {
    createViewReportRecipients{
      create
      update
      delete
    }
  }
`

export default {
  name: "CoreAPI",
  data() {
    return {
      created: null,
      updated: null,
      deleted: null,
      error: null,
      loading: false
    }
  },
  methods: {
    createAccountOwnerViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewAccountOwners
        })
        .then(res => {
          this.created = res.data.createViewAccountOwners.create;
          this.updated = res.data.createViewAccountOwners.update;
          this.deleted = res.data.createViewAccountOwners.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createBillingArticlesViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewBillingArticles
        })
        .then(res => {
          this.created = res.data.createViewBillingArticles.create;
          this.updated = res.data.createViewBillingArticles.update;
          this.deleted = res.data.createViewBillingArticles.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createVendorViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewVendors
        })
        .then(res => {
          this.created = res.data.createViewVendors.create;
          this.updated = res.data.createViewVendors.update;
          this.deleted = res.data.createViewVendors.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createSalesOfficeViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewSalesOffices
        })
        .then(res => {
          this.created = res.data.createViewSalesOffices.create;
          this.updated = res.data.createViewSalesOffices.update;
          this.deleted = res.data.createViewSalesOffices.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createSalesAgentViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewSalesAgents
        })
        .then(res => {
          this.created = res.data.createViewSalesAgents.create;
          this.updated = res.data.createViewSalesAgents.update;
          this.deleted = res.data.createViewSalesAgents.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createCountryViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewCountries
        })
        .then(res => {
          this.created = res.data.createViewCountries.create;
          this.updated = res.data.createViewCountries.update;
          this.deleted = res.data.createViewCountries.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createNUTS1RegionViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewNUTS1Regions
        })
        .then(res => {
          this.created = res.data.createViewNUTS1Regions.create;
          this.updated = res.data.createViewNUTS1Regions.update;
          this.deleted = res.data.createViewNUTS1Regions.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createNUTS2RegionViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewNUTS2Regions
        })
        .then(res => {
          this.created = res.data.createViewNUTS2Regions.create;
          this.updated = res.data.createViewNUTS2Regions.update;
          this.deleted = res.data.createViewNUTS2Regions.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createNUTS3RegionViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewNUTS3Regions
        })
        .then(res => {
          this.created = res.data.createViewNUTS3Regions.create;
          this.updated = res.data.createViewNUTS3Regions.update;
          this.deleted = res.data.createViewNUTS3Regions.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createPostalCodeViewData(){
      this.loading = true;
      this.$apollo.mutate({
        mutation: createViewPostalCodes
      })
          .then(res => {
            this.created = res.data.createViewPostalCodes.create;
            this.updated = res.data.createViewPostalCodes.update;
            this.deleted = res.data.createViewPostalCodes.delete;
            this.loading = false;
          })
          .catch(error => {
            console.error(error);
            this.error = error.message;
            this.loading = false;
          })
    },
    createClientViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewClients
        })
        .then(res => {
          this.created = res.data.createViewClients.create;
          this.updated = res.data.createViewClients.update;
          this.deleted = res.data.createViewClients.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createProductViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewProducts
        })
        .then(res => {
          this.created = res.data.createViewProducts.create;
          this.updated = res.data.createViewProducts.update;
          this.deleted = res.data.createViewProducts.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createProductGroupViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewProductGroups
        })
        .then(res => {
          this.created = res.data.createViewProductGroups.create;
          this.updated = res.data.createViewProductGroups.update;
          this.deleted = res.data.createViewProductGroups.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createReportRecipientViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewReportRecipients
        })
        .then(res => {
          this.created = res.data.createViewReportRecipients.create;
          this.updated = res.data.createViewReportRecipients.update;
          this.deleted = res.data.createViewReportRecipients.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createContractViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewContracts
        })
        .then(res => {
          this.created = res.data.createViewContracts.create;
          this.updated = res.data.createViewContracts.update;
          this.deleted = res.data.createViewContracts.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    createRevenueAccountViewData(){
      this.loading = true;
      this.$apollo.mutate({
          mutation: createViewRevenueAccounts
        })
        .then(res => {
          this.created = res.data.createViewRevenueAccounts.create;
          this.updated = res.data.createViewRevenueAccounts.update;
          this.deleted = res.data.createViewRevenueAccounts.delete;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
          this.loading = false;
        })
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  },
}
</script>

<style scoped>
  .btn-view-trigger{
    text-align: left;
    width:100%;
  }
</style>